import Head from 'next/head'
import { useState, useEffect } from 'react';
import { useSession, signIn, signOut } from 'next-auth/react'
import { getCookies } from 'cookies-next';
import Router from 'next/router'

export default function  Home() {
  const { data: session } = useSession()

  useEffect(()=>{
    const pc = getCookies('ref')
    if(pc){
        if(pc.ref != ""){
          if(pc.ref == "donate"){
            Router.replace('/donate')
          }else{
            goHelpHero()
          }
        }else{
          goHelpHero()
        }
    }else{
      goHelpHero()
    }
  },[])

  const goHelpHero = () => {
    Router.replace('/donate')
    //window.location.href = "https://helphero.io";
  }


  return (
    <div className="container">
      <Head>
        <title>HelpHero - Donate</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main>
        <div className="main">
          You'll be redirected in a moment..
        </div>
      </main>

      <style jsx>{`
        main {
          padding: 5rem 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}</style>

      <style jsx global>{`
        html,
        body {
          padding: 0;
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
            Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
            sans-serif;
        }

        * {
          box-sizing: border-box;
        }
      `}</style>
    </div>
  )
}
